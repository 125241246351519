import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import { HeaderStyle, ByLine } from "../components/styles/main"

const ContentWrapper = styled.div`
  padding: 1rem 2rem;
`
export default function Template({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ContentWrapper>
        <HeaderStyle>{post.frontmatter.title}</HeaderStyle>
        <ByLine>By: {post.frontmatter.by}</ByLine>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </ContentWrapper>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        by
      }
    }
  }
`
